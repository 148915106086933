// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-podcast-bp-001-phong-shui-mdx": () => import("./../../../src/pages/podcast/bp001-phong-shui.mdx" /* webpackChunkName: "component---src-pages-podcast-bp-001-phong-shui-mdx" */),
  "component---src-pages-podcast-bp-002-lazer-eyes-mdx": () => import("./../../../src/pages/podcast/bp002-lazer-eyes.mdx" /* webpackChunkName: "component---src-pages-podcast-bp-002-lazer-eyes-mdx" */),
  "component---src-pages-podcast-bp-003-travellers-mdx": () => import("./../../../src/pages/podcast/bp003-travellers.mdx" /* webpackChunkName: "component---src-pages-podcast-bp-003-travellers-mdx" */),
  "component---src-pages-podcast-bp-004-evil-japanese-money-laundering-mdx": () => import("./../../../src/pages/podcast/bp004-evil-japanese-money-laundering.mdx" /* webpackChunkName: "component---src-pages-podcast-bp-004-evil-japanese-money-laundering-mdx" */),
  "component---src-pages-podcast-bp-005-drawing-a-picture-mdx": () => import("./../../../src/pages/podcast/bp005-drawing-a-picture.mdx" /* webpackChunkName: "component---src-pages-podcast-bp-005-drawing-a-picture-mdx" */),
  "component---src-pages-podcast-bp-006-cat-gif-offsprings-mdx": () => import("./../../../src/pages/podcast/bp006-cat-gif-offsprings.mdx" /* webpackChunkName: "component---src-pages-podcast-bp-006-cat-gif-offsprings-mdx" */),
  "component---src-pages-podcast-bp-007-hitlers-dirty-surrogates-mdx": () => import("./../../../src/pages/podcast/bp007-hitlers-dirty-surrogates.mdx" /* webpackChunkName: "component---src-pages-podcast-bp-007-hitlers-dirty-surrogates-mdx" */),
  "component---src-pages-podcast-index-tsx": () => import("./../../../src/pages/podcast/index.tsx" /* webpackChunkName: "component---src-pages-podcast-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

